<template>
  <div class="Header-layout">
    <header class="navbar navbar-dark bg-header" style="color: #dfdfdf">
      <div class="d-flex">
        <b-navbar-brand href="/" :title="$t('access.platform')">
          <picture>
            <source srcset="/admin/logo/logotype.webp" type="image/webp" />
            <img src="/admin/logo/logotype.png" />
          </picture>
        </b-navbar-brand>
        <span class="d-none d-md-block">
          <span>
            {{ $t('access.admSys') }}
          </span>
        </span>
      </div>

      <div v-if="!user.isGuest" class="d-flex">
        <notifications />

        <communication-chats-unread />

        <button class="btn btn-dark bg-header" @click="logout">{{ $t('access.logout') }}</button>
      </div>
    </header>

    <div v-if="!user.isGuest" class="Header-menu">
      <MainMenu></MainMenu>
    </div>
  </div>
</template>

<script>
import '../../../../images/logo_white.png';
import Services from '../../../../scripts/services/services';
import modalMixin from '../../../mixins/modal.js';
import MainMenu from '../../main-menu/main-menu.vue';
import CommunicationChatsUnread from './communication-chats-unread.vue';
import Notifications from './notifications.vue';

export default {
  name: 'LayoutHeader',
  components: { Notifications, CommunicationChatsUnread, MainMenu },
  mixins: [modalMixin],
  props: {
    onIndex: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      Services.net()
        .post(this.route('logout'))
        .then(
          () => {
            window.location.href = this.route('page.login');
          },
          () => {
            this.showMessageBox({ title: this.$t('shared.error.title'), text: this.$t('access.error') });
          }
        );
    },
  },
};
</script>
<style>
.bg-header {
  background-color: var(--header-background-color) !important;
  color: var(--header-font-color) !important;
  border-color: var(--header-background-color) !important;
}
.Header-layout .only-mobile {
  display: none;
}

.Header-layout .only-desktop {
  display: block;
}

@media screen and (max-width: 1023px) {
  .Header-layout .only-mobile {
    display: block;
  }

  .Header-layout .only-desktop {
    display: none;
  }
}

.Header {
  position: relative;
  background-color: #141116;
  height: 80px;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}

.Header a,
.Header a:hover {
  color: #fff;
}

.small {
  color: #7d7d7d;
  font-size: 12px;
}

.Header-logo {
  text-align: center;
  height: 40px;
  line-height: 80px;
}

.Header-logo img {
  height: 100%;
}

.Header-logo .large {
  display: auto;
}

.Header-logo .small {
  display: none;
}

@media screen and (max-width: 1290px) {
  .Header-logo .large {
    display: none;
  }

  .Header-logo .small {
    display: inline-block;
  }
}

.Header-tools {
  position: absolute;
  top: 0;
  display: flex;
  height: 80px;
  align-items: center;
}

.Header-tools .only-mobile {
  display: none;
}

.Header-tools .only-desktop {
  display: inline-block;
}

@media screen and (max-width: 1023px) {
  .Header,
  .Header-logo,
  .Header-tools {
    height: 48px;
  }

  .Header-logo {
    line-height: 48px;
    height: 29px;
  }

  .Header-tools .only-mobile {
    display: inline-block;
  }

  .Header-tools .only-desktop {
    display: none;
  }
}

.Header-tools > div {
  width: 50vw;
}

.Header-menuBtn {
  cursor: pointer;
  padding-left: 16px;
}

.Header-rightBar {
  text-align: right;
  padding-right: 41px;
}

.Header-rightBar > div {
  display: inline-block;
  padding-left: 28px;
}

@media screen and (max-width: 1023px) {
  .Header-rightBar {
    padding-right: 16px;
  }

  .Header-rightBar > div {
    display: inline-block;
    padding-left: 22px;
  }
}
.d-none {
  align-self: center;
}
.navbar-brand img {
  height: 45px !important;
}
</style>
