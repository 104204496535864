var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Header-layout" }, [
    _c(
      "header",
      {
        staticClass: "navbar navbar-dark bg-header",
        staticStyle: { color: "#dfdfdf" },
      },
      [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "b-navbar-brand",
              { attrs: { href: "/", title: _vm.$t("access.platform") } },
              [
                _c("picture", [
                  _c("source", {
                    attrs: {
                      srcset: "/admin/logo/logotype.webp",
                      type: "image/webp",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", { attrs: { src: "/admin/logo/logotype.png" } }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-md-block" }, [
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("access.admSys")) +
                    "\n        "
                ),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.user.isGuest
          ? _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("notifications"),
                _vm._v(" "),
                _c("communication-chats-unread"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-dark bg-header",
                    on: { click: _vm.logout },
                  },
                  [_vm._v(_vm._s(_vm.$t("access.logout")))]
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    !_vm.user.isGuest
      ? _c("div", { staticClass: "Header-menu" }, [_c("MainMenu")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }