<template>
  <a v-if="count" :href="getRoute('communications.chats.unread')" class="btn btn-dark bg-header">
    <fa-icon icon="envelope" size="lg" />
    <span class="badge badge-info">{{ count }}</span>
  </a>
</template>

<script>
import Services from '../../../../scripts/services/services.js';

export default {
  name: 'CommunicationChatsUnread',
  data() {
    return {
      count: 0,
    };
  },
  created() {
    this.loadCount();

    setInterval(() => {
      this.loadCount();
    }, 1000 * 60);
  },
  methods: {
    loadCount() {
      Services.net()
        .get(this.getRoute('communications.chats.unread.count'))
        .then((data) => {
          this.count = data.count;
        });
    },
  },
};
</script>

<style scoped></style>
