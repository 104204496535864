<template>
  <nav class="sidebar">
    <b-navbar toggleable="xl" class="p-0 mobile">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <div class="sidebar-sticky">
          <menu-item v-for="(item, index) in menu" :key="index" :item="item"></menu-item>
        </div>
      </b-collapse>
    </b-navbar>
    <div class="desktop">
      <HorizontalMenuItem v-for="(item, index) in menu" :key="index" :item="item"></HorizontalMenuItem>
    </div>
  </nav>
</template>

<script>
import MenuItem from './menu-item.vue';
import HorizontalMenuItem from './horizontal-menu-item.vue';

export default {
  name: 'MainMenu',
  components: {
    MenuItem,
    HorizontalMenuItem,
  },
  computed: {
    menu() {
      return this.$store.state.layout.menu;
    },
  },
};
</script>
<style>
.Header-layout .sidebar .mobile {
  display: none;
}

.Header-layout .sidebar .desktop {
  display: block;
  padding-left: 12px;
  justify-content: space-around;
}

@media screen and (max-width: 1023px) {
  .Header-layout .sidebar .mobile {
    display: block;
  }

  .Header-layout .sidebar .desktop {
    display: none;
  }
}
.sidebar-sticky {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
