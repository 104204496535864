import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowUp,
  faBarcode,
  faBox,
  faCalendarAlt,
  faCamera,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faCog,
  faCommentDots,
  faCompressArrowsAlt,
  faCopy,
  faDollarSign,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faEye,
  faFileArchive,
  faFileDownload,
  faFileExcel,
  faFileInvoice,
  faFileUpload,
  faFlag,
  faInfo,
  faLink,
  faListUl,
  faPencilAlt,
  faPercent,
  faPercentage,
  faPlus,
  faPrint,
  faQuestionCircle,
  faRubleSign,
  faSave,
  faSearch,
  faStop,
  faTimes,
  faTrashAlt,
  faTruck,
  faTruckLoading,
  faUnlink,
  faWindowClose,
  faRedoAlt,
  faPaperPlane,
  faEyeSlash,
  faGlobe,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(faTrashAlt, faTimes);
library.add(faPlus);
library.add(faEye);
library.add(faArrowUp);
library.add(faStop, faExclamationTriangle);
library.add(faEdit, faPencilAlt);
library.add(faDownload, faFileDownload);
library.add(faCheck, faCheckCircle);
library.add(faAngleLeft, faAngleRight, faAngleDown, faCaretDown);
library.add(faQuestionCircle);
library.add(faExpandArrowsAlt, faCompressArrowsAlt);
library.add(faCalendarAlt);
library.add(faEnvelope, faCommentDots);
library.add(faCamera);
library.add(faDollarSign);
library.add(faPercentage);
library.add(faCog);
library.add(faPrint);
library.add(faArchive);
library.add(faTruck);
library.add(faRubleSign);
library.add(faSave);
library.add(faInfo);
library.add(faLink);
library.add(faFileArchive);
library.add(faFileExcel);
library.add(faFileUpload);
library.add(faPercent);
library.add(faCopy);
library.add(faAngleUp);
library.add(faBox);
library.add(faTruckLoading);
library.add(faUnlink);
library.add(faExclamation);
library.add(faFileInvoice);
library.add(faBarcode);
library.add(faSearch);
library.add(faListUl);
library.add(faFlag);
library.add(faRedoAlt);
library.add(faPaperPlane);
library.add(faEyeSlash);
library.add(faGlobe);
library.add(faWindowClose);
library.add(faInfoCircle);
