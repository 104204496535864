import Service from '@scripts/services/services';
import axios from 'axios';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const loadedLocales = [];
const defaultLocale = 'ru';

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

export const setLocaleAsync = (locale) => {
  // If the same language
  if (i18n.locale === locale) return Promise.resolve(setI18nLocale(locale));

  // If the language was already loaded
  if (loadedLocales.includes(locale)) return Promise.resolve(setI18nLocale(locale));

  return loadLocale(locale);
};

export const loadDefaultLocale = () => loadLocale(defaultLocale);

function setI18nLocale(locale) {
  i18n.locale = locale;
  axios.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html').setAttribute('lang', locale);
  return locale;
}

function loadLocale(locale) {
  return Service.net()
    .get(`/admin/translations/${locale}.json`)
    .then((data) => {
      i18n.setLocaleMessage(locale, data);
      loadedLocales.push(locale);
      return setI18nLocale(locale);
    });
}
