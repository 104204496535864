<template>
  <layout-main>
    <div class="page-login">
      <h1 class="h2 text-center">{{ $t('login.authorisation') }}</h1>
      <form class="mt-5" @submit.prevent.stop="login">
        <div class="form-group">
          <label class="control-label" for="email">{{ $t('login.title') }}</label>
          <input
            id="email"
            v-model="form_data.email"
            v-validate="'required'"
            class="form-control"
            name="email"
            type="text"
          />
          <span v-if="errors.has('email')" class="validation-error">
            {{ $t(errors.first('email')) }}
          </span>
        </div>
        <div class="form-group">
          <label class="control-label" for="password">{{ $t('shared.password') }}</label>
          <div class="input-group">
            <input
              id="password"
              v-model="form_data.password"
              v-validate="'required'"
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              name="password"
            />
            <div class="input-group-append">
              <button class="btn" type="button" @click="togglePasswordShow">
                <fa-icon :icon="showPassword ? 'eye-slash' : 'eye'" class="text-muted" fixed-width />
              </button>
            </div>
          </div>
          <span v-if="errors.has('password')" class="validation-error">
            {{ $t(errors.first('password')) }}
          </span>
        </div>
        <div class="form-group">
          <button class="btn btn-primary" @click.prevent="login">{{ $t('login.enter') }}</button>
          <a :href="route('page.restorePassword')" class="ml-3">{{ $t('login.recovery') }}</a>
        </div>
        <div class="form-group">
          <label class="control-label" for="language">{{ $t('login.language') }}</label>
          <select id="language" v-model="form_data.selectedLanguage" class="form-control">
            <option value="default">{{ $t('login.default') }}</option>
            <option v-for="language in systemLangs" :key="language.code" :value="language.code">
              {{ language.name }}
            </option>
          </select>
        </div>
      </form>
    </div>
  </layout-main>
</template>

<script>
import { mapGetters } from 'vuex';
import Services from '../../../scripts/services/services';
import modalMixin from '../../mixins/modal';

import './Login.css';

export default {
  name: 'PageLogin',
  mixins: [modalMixin],
  data() {
    return {
      showPassword: false,
      form_data: {
        selectedLanguage: 'default',
      },
    };
  },
  computed: {
    ...mapGetters('langs', ['systemLang', 'systemLangs']),
  },
  methods: {
    togglePasswordShow() {
      this.showPassword = !this.showPassword;
    },
    login() {
      let errorMessage = this.$t('login.error');

      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        Services.net()
          .post(this.route('login'), null, this.form_data)
          .then(
            (data) => {
              if (data.status === 'ok') {
                window.location.href = this.route('home');
              } else {
                this.showMessageBox({ title: this.$t('shared.error.title'), text: errorMessage });
              }
            },
            () => {
              this.showMessageBox({ title: this.$t('shared.error.title'), text: errorMessage });
            }
          );
      });
    },
  },
};
</script>

<style>
.input-group-append .btn {
  border-color: #ced4da;
}
</style>
