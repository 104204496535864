<template>
  <span>
    <span v-if="type === basketTypes.product" class="badge badge-primary">{{ $t('shared.products') }}</span>
    <span v-if="type === basketTypes.master" class="badge badge-info">{{ $t('marketing.masterclass.title') }}</span>
    <span v-if="type === basketTypes.certificate" class="badge badge-info">{{ $t('product.cert.title') }}</span>
  </span>
</template>

<script>
export default {
  name: 'OrderType',
  props: {
    type: { type: Number, required: true },
  },
};
</script>
