var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.count
    ? _c(
        "a",
        {
          staticClass: "btn btn-dark bg-header",
          attrs: { href: _vm.getRoute("communications.chats.unread") },
        },
        [
          _c("fa-icon", { attrs: { icon: "envelope", size: "lg" } }),
          _vm._v(" "),
          _c("span", { staticClass: "badge badge-info" }, [
            _vm._v(_vm._s(_vm.count)),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }