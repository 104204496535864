import VeeValidate, { Validator } from 'vee-validate';
import Vue from 'vue';
import { validationMixin as mixin } from 'vuelidate';
import {
  helpers,
  maxLength as maxl,
  maxValue as maxv,
  minLength as minl,
  minValue as minv,
  required as r,
  sameAs as sa,
} from 'vuelidate/lib/validators';
import Helpers from './helpers';

import Regex from './regex';

Vue.use(VeeValidate);

// dictionary
const dictionary = {
  ru: {
    messages: {
      required: () => 'shared.errors.field.required',
      decimal: () => 'shared.errors.field.decimal',
      integer: () => 'shared.errors.field.integer',
      email: () => 'shared.errors.field.email',
      confirmed: () => 'shared.errors.field.confirmed',
      max: (field, [length]) => ['shared.errors.field.max', { length }],
      min: (field, [length]) => ['shared.errors.field.min', { length }],
    },
  },
};

export const requiredIfAnyFilled = (translations) => {
  const hasEmpty = Object.values(translations).some((el) => el === '' || el === null);
  const hasFilled = Object.values(translations).some((el) => el);
  return hasEmpty !== hasFilled;
};

export const requiredAllFields = (translations) => {
  return !Object.values(translations).some((el) => el === '' || el === null);
};

Validator.localize('ru', dictionary.ru);

Validator.extend('name', {
  getMessage: () => 'shared.errors.field.name',
  validate: (value) => Regex.nameAll.test(value),
});

Validator.extend('nameRu', {
  getMessage: () => 'shared.errors.field.nameRu',
  validate: (value) => Regex.nameRu.test(value),
});

Validator.extend('nameEn', {
  getMessage: () => 'shared.errors.field.nameEn',
  validate: (value) => Regex.nameEn.test(value),
});

Validator.extend('password', {
  getMessage: () => 'shared.errors.field.password',
  validate: (value) => Regex.password.test(value),
});

Validator.extend('tel', {
  getMessage: () => 'shared.errors.field.tel',
  validate: (value) => Regex.tel.test(value),
});

Validator.extend('kpp', {
  getMessage: () => 'shared.errors.field.kpp',
  validate: (value) => Regex.kpp.test(value),
});

Validator.extend('inn', {
  getMessage: () => 'shared.errors.field.inn',
  validate: (value) => {
    const inn = value;

    let isCorrect = false;
    let checkdigit;
    let firstCheckdigit;
    let secondCheckdigit;

    /* ИНН может быть 10 или 12-значным и в каждом случае имеет свою логику проверки */
    switch (inn.length) {
      case 10:
        checkdigit = Helpers.countCheckdigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (checkdigit === parseInt(inn[9], 10)) {
          isCorrect = true;
        }
        break;
      case 12:
        firstCheckdigit = Helpers.countCheckdigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        secondCheckdigit = Helpers.countCheckdigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (firstCheckdigit === parseInt(inn[10], 10) && secondCheckdigit === parseInt(inn[11], 10)) {
          isCorrect = true;
        }
        break;
      default:
    }

    return isCorrect;
  },
});

Validator.extend('minLength', {
  getMessage(field, [length]) {
    return ['shared.errors.field.minLength', { length }];
  },
  validate(value, [length]) {
    const l = +length;
    return value.length >= l;
  },
});

Validator.extend('maxLength', {
  getMessage(field, [length]) {
    return ['shared.errors.field.maxLength', { length }];
  },
  validate(value, [length]) {
    const l = +length;
    return value.length <= l;
  },
});

Validator.extend('maxSymbols', {
  getMessage(field, [length]) {
    return ['shared.errors.field.maxSymbols', { length }];
  },
  validate(value, [length]) {
    const l = +length;
    return value.length <= l;
  },
});

Validator.extend('requiredIfAnyFilled', {
  getMessage: () => 'shared.errors.field.requiredIfAnyFilled',
  validate: (value, { translations }) => requiredIfAnyFilled(translations),
  paramNames: ['translations'],
});

Validator.extend('requiredAllFields', {
  getMessage: () => 'shared.errors.field.requiredIfAnyFilled',
  validate: (value, { translations }) => !Object.values(translations).some((el) => el === ''),
  paramNames: ['translations'],
});

export const password = helpers.regex('password', Regex.password);
export const tel = helpers.regex('tel', Regex.tel);
export const email = helpers.regex('email', Regex.email);
export const required = r;
export const sameAs = sa;
export const minLength = minl;
export const maxLength = maxl;
export const minValue = minv;
export const maxValue = maxv;
export const validationMixin = mixin;
