import Services from '@scripts/services/services';

export default {
  namespaced: true,

  state: {
    code: '',
    name: '',
    shortName: '',
    symbol: '',
    currencies: [],
    mpCurrency: null,
  },

  getters: {
    currencies: (state) => state.currencies,
    getCurrencyById: (state) => (id) => {
      return state.currencies.find((el) => el.id === id) ?? null;
    },
    getCurrencySymbol: (state) => (id) => {
      const currency = state.currencies.find((el) => el.id === id);
      return currency.symbol || currency.abbreviation;
    },
    getCurrencyAbbreviation: (state) => (id) => state.currencies.find((el) => el.id === id)?.abbreviation,
  },

  mutations: {
    SET_CURRENCIES(state, payload) {
      state.currencies = payload;
      state.mpCurrency = state.currencies.find((el) => el.mp_account === true);

      state.code = state.mpCurrency.code;
      state.name = state.mpCurrency.name;
      state.shortName = state.mpCurrency.abbreviation;
      state.symbol = state.mpCurrency.symbol;
    },
  },

  actions: {
    async getCurrencies({ commit, rootGetters }) {
      try {
        const { currency } = await Services.net().get(rootGetters['getRoute']('currency.pagination'));
        commit('SET_CURRENCIES', currency);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
