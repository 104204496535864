import '@/app';
import initCurrencyPlugin from '@/plugins/currency';
import { i18n, loadDefaultLocale, setLocaleAsync } from '@/plugins/i18n';
import media from '@/plugins/MediaPlugin';
import store from '@/store/store';
import Vue from 'vue';
import Login from './Login.vue';

export default (async () => {
  await initCurrencyPlugin({ store });
  await loadDefaultLocale();

  const locale = store.getters['langs/systemLang'];
  await setLocaleAsync(locale);

  // Boot the current Vue component
  const root = document.getElementById('app');
  const vueOptions = Object.assign({
    store,
    mq: media,
    i18n,
    render: (h) => h(Login, { props: JSON.parse(root.dataset.props) }),
  });
  window.vue = new Vue(vueOptions).$mount(root);
})();
