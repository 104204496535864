import { render, staticRenderFns } from "./communication-chats-unread.vue?vue&type=template&id=45b0679e&scoped=true"
import script from "./communication-chats-unread.vue?vue&type=script&lang=js"
export * from "./communication-chats-unread.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b0679e",
  null
  
)

export default component.exports